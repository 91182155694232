import Button from "../../components/Button/Button";
import HeadbandBellyPremium from "../../components/HeadbandBellyPremium/HeadbandBellyPremium";
import Promotion from "../../components/Promotion/Promotion";
import TemporaryPromotion from "../../components/TemporaryPromotion/TemporaryPromotion";
import ApiService from "../../services/ApiService";
import { useGoBack } from "../../services/NavigationService";
import "./CourseV2.scss";
import LessonCardV2 from "./components/LessonCardV2/LessonCardV2";
import _ from "lodash";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

const CourseV2 = () => {
  const params = useParams();
  const [isLoaded, setIsLoaded] = useState(false);
  const [course, setCourse] = useState<any>([]);
  const [courseModules, setCourseModules] = useState<
    {
      name: string;
      id: string | null;
    }[]
  >([]);
  const goBack = useGoBack();

  useEffect(() => {
    ApiService.get(`e-learning/courses/${params.course_id}`)
      .then((resp) => {
        setCourseModules(resp.data.modules);
        setCourse(resp.data);
        setIsLoaded(true);
      })
      .catch((err) => {
        console.error(err);
        toast.error(
          "Une erreur s'est produite lors de la récupération des données du cours. Veuillez réessayer plus tard."
        );
      });
  }, [params]);

  const findColor = (name: string, idx: number) => {
    if (idx === 0 || name.toLowerCase().includes("après"))
      return "rgb(251, 182, 185)";
    else if (
      name.toLowerCase().includes("semaine") ||
      name.toLowerCase().includes("exercice")
    )
      return "rgb(187, 187, 255)";
    return "rgb(233, 13, 47)";
  };

  return (
    <div id="course-v2">
      <HeadbandBellyPremium />
      <TemporaryPromotion />
      <Promotion />
      <div id="content-course-v2">
        <div id="course-v2-button">
          <Button category="primary" onClick={() => goBack()}>
            &lt; Retour
          </Button>
        </div>
        <div
          id="title-container"
          style={{ backgroundImage: `url(${course.illustration})` }}
        >
          <h2>{course.name?.split(":")?.[1]?.replace("TBL", "")}</h2>
          <div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 1440 320"
              id="bottom-curve"
            >
              <path
                fill="#ffffff"
                fillOpacity="1"
                d="M0,224L80,213.3C160,203,320,181,480,170.7C640,160,800,160,960,186.7C1120,213,1280,267,1360,293.3L1440,320V0H1360C1280,0,1120,0,960,0C800,0,640,0,480,0C320,0,160,0,80,0H0Z"
              ></path>
            </svg>
          </div>
        </div>
        <div className="container">
          {isLoaded &&
            courseModules
              .filter((module) => !module.name.includes("exercice"))
              .map((module, idx) => (
                <div key={`div-${idx}`}>
                  <h4 style={{ backgroundColor: findColor(module.name, idx) }}>
                    {module.name}
                  </h4>
                  {_.uniqBy(
                    course.lessons.filter((lesson: any) =>
                      lesson.modules.includes(module.name)
                    ),
                    "id"
                  ).map((lesson: any, index: number) => (
                    <LessonCardV2
                      key={`lesson-card-${index}`}
                      illustration={
                        lesson?.preview || "/images/preview_default.png"
                      }
                      title={lesson.name}
                      link={`/lessons/${lesson.id}`}
                    />
                  ))}
                </div>
              ))}
        </div>
      </div>
    </div>
  );
};

export default CourseV2;
