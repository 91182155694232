import Card from "../../components/Card/Card";
import HeadbandBellyPremium from "../../components/HeadbandBellyPremium/HeadbandBellyPremium";
import LeftPanel from "../../components/LeftPanel/LeftPanel";
import Promotion from "../../components/Promotion/Promotion";
import TemporaryPromotion from "../../components/TemporaryPromotion/TemporaryPromotion";
import WidgetPanel from "../../components/WidgetPanel/WidgetPanel";
import useWindowSize from "../../hooks/useWindowSize";
import ApiService from "../../services/ApiService";
import "./Training.scss";
import React from "react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const Training = () => {
  const navigate = useNavigate();
  const [ownedCourses, setOwnedTraining] = useState<any>([]);
  const [otherCourses, setOtherCourses] = useState<any>([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const { width } = useWindowSize();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const allCourses = await ApiService.get("e-learning/courses/");
        const userCoursesAnswer = await ApiService.get("users/courses");
        const allTraining = allCourses.data.filter(
          (courses: any) =>
            courses.type === "course" ||
            (courses.type === "subscription" &&
              courses.name.includes("Belly Premium"))
        );
        const trainings = allTraining.reduce(
          (acc: any, training: any) => {
            return userCoursesAnswer.data.includes(training.id)
              ? {
                  ownedCourses: [...(acc.ownedCourses ?? []), training],
                  otherCourses: acc.otherCourses ?? [],
                }
              : {
                  ownedCourses: acc.ownedCourses ?? [],
                  otherCourses: [...(acc.otherCourses ?? []), training],
                };
          },
          { ownedCourses: [], otherTrainig: [] }
        );
        setOwnedTraining(trainings.ownedCourses);
        setOtherCourses(trainings.otherCourses);
        setIsLoaded(true);
      } catch (err) {
        toast.error(
          "Une erreur s'est produite lors de la récupération des données du cours. Veuillez réessayer plus tard."
        );
        console.error(err);
      }
    };
    fetchData();
  }, []);

  return (
    <div id="training">
      <HeadbandBellyPremium />
      <TemporaryPromotion />
      <Promotion />
      <section>
        <LeftPanel />
        <div id="main">
          <section>
            <div id="training-header">
              <h2>Tous mes entraînements</h2>
            </div>
            <div id="my-training-list">
              {isLoaded ? (
                ownedCourses.length ? (
                  ownedCourses.map((course: any, index: number) => (
                    <Card
                      key={`card-owned-courses-${index}`}
                      img={
                        <img
                          src={course.preview}
                          alt={`illustration-training-${index}`}
                        />
                      }
                      title={course.name}
                      action={() =>
                        navigate(
                          course.name.toLowerCase().includes("belly premium")
                            ? "/belly-premium"
                            : `/courses/${course.id}`
                        )
                      }
                      actionTitle="Reprendre"
                      disabled={false}
                    />
                  ))
                ) : (
                  <p className="none-paragraph">
                    Vous ne possédez aucun programme pour le moment.
                  </p>
                )
              ) : (
                <p>Chargement des entraînements...</p>
              )}
            </div>
            {isLoaded && !!otherCourses.length && (
              <React.Fragment>
                <div className="training-header">
                  <h2>Aller plus loin</h2>
                </div>
                <div id="other-training-list">
                  {otherCourses.map((course: any, index: number) => (
                    <Card
                      key={`card-other-training-${index}`}
                      img={
                        <img
                          src={course.preview}
                          alt={`illustration-training-${index}`}
                        />
                      }
                      title={course.name}
                      action={() =>
                        (document.location.href =
                          course?.purchase_url ??
                          "https://thebellylab.com/belly-premium/#lesformules")
                      }
                      actionTitle="Obtenir"
                      disabled={true}
                    />
                  ))}
                </div>
              </React.Fragment>
            )}
          </section>
          {width <= 1000 && <WidgetPanel />}
        </div>
        {width > 1000 && <WidgetPanel />}
      </section>
    </div>
  );
};

export default Training;
