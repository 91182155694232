import Button from "../../components/Button/Button";
import HeadbandBellyPremium from "../../components/HeadbandBellyPremium/HeadbandBellyPremium";
import Promotion from "../../components/Promotion/Promotion";
import TemporaryPromotion from "../../components/TemporaryPromotion/TemporaryPromotion";
import ApiService from "../../services/ApiService";
import { useGoBack } from "../../services/NavigationService";
import {
  findCourseColor,
  getNextLesson,
  getTextColor,
  sendEnded,
} from "./Lesson.function";
import "./Lesson.scss";
import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { toast } from "react-toastify";

const Lesson = () => {
  const params = useParams();
  const [isLoaded, setIsLoaded] = useState(false);
  const [lesson, setLesson] = useState<any>([]);
  const [course, setCourse] = useState<any>([]);
  const [isEnded, setIsEnded] = useState<string | null>(null);
  const [nextLesson, setNextLesson] = useState<string | null>();
  const goBack = useGoBack();

  useEffect(() => {
    setIsLoaded(false);
    const _fetchData = async () => {
      try {
        const respLesson = await ApiService.get(
          `e-learning/lessons/${params.lesson_id}`
        );
        const respCourse = await ApiService.get(
          `e-learning/courses/${respLesson.data.course_id}`
        );
        setLesson(respLesson.data);
        setCourse(respCourse.data);
        setNextLesson(
          getNextLesson(
            respLesson.data.id,
            respCourse.data.lessons.map((lesson: any) => lesson.id)
          )
        );
        setIsLoaded(true);
      } catch (err) {
        console.error(err);
        toast.error(
          "An error occurred while fetching lesson's data. Please try again later."
        );
      }
    };
    _fetchData();
  }, [params]);

  return (
    <div id="lesson">
      <HeadbandBellyPremium />
      <TemporaryPromotion />
      <Promotion />
      <div id="return-button">
        <Button category="primary" onClick={() => goBack()}>
          &lt; Retour
        </Button>
      </div>
      {isLoaded && (
        <div id="lesson-container">
          {lesson.lesson_type !== "video" ? (
            <div id="lesson-button">
              <Link to={lesson.source}>
                <button>Accéder au contenu</button>
              </Link>
            </div>
          ) : (
            <video
              id="lesson-video"
              controls
              controlsList="nodownload"
              onTimeUpdate={(event) =>
                isEnded !== params.lesson_id
                  ? sendEnded(event, params.lesson_id!, setIsEnded)
                  : null
              }
            >
              <source src={lesson.source} type="video/mp4"></source>
            </video>
          )}
          <div
            id="lesson-description"
            style={{ backgroundColor: findCourseColor(course).secondaryColor }}
          >
            <div>
              <h1>{lesson.name}</h1>
              <h2>
                {course.name.replace(/\d+/g, "")} &gt; {lesson.modules[0].name}{" "}
                &gt; <u>{lesson.name}</u>
              </h2>
            </div>
            <div>
              {nextLesson && (
                <Link to={`/lessons/${nextLesson}`}>
                  <button
                    style={{
                      backgroundColor: findCourseColor(course).primaryColor,
                      color: getTextColor(findCourseColor(course).primaryColor),
                    }}
                  >
                    Leçon suivante
                  </button>
                </Link>
              )}
              <Link to="/">
                <button>Retour à mon espace</button>
              </Link>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Lesson;
