import Card from "../../components/Card/Card";
import HeadbandBellyPremium from "../../components/HeadbandBellyPremium/HeadbandBellyPremium";
import LeftPanel from "../../components/LeftPanel/LeftPanel";
import Promotion from "../../components/Promotion/Promotion";
import TemporaryPromotion from "../../components/TemporaryPromotion/TemporaryPromotion";
import WidgetPanel from "../../components/WidgetPanel/WidgetPanel";
import useWindowSize from "../../hooks/useWindowSize";
import ApiService from "../../services/ApiService";
import "./Challenges.scss";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const Challenges = () => {
  const navigate = useNavigate();
  const [challenges, setChallenges] = useState<any>([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [userChallenges, setUserChallenges] = useState<string[]>([]);
  const { width } = useWindowSize();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const allCourses = await ApiService.get("e-learning/courses/");
        const userCoursesAnswer = await ApiService.get("users/courses");
        setChallenges(
          allCourses.data.filter((courses: any) => courses.type === "challenge")
        );
        setUserChallenges(userCoursesAnswer.data);
        setIsLoaded(true);
      } catch (err) {
        toast.error(
          "Une erreur s'est produite lors de la récupération des données du cours. Veuillez réessayer plus tard."
        );
        console.error(err);
      }
    };
    fetchData();
  }, []);

  return (
    <div id="challenges">
      <HeadbandBellyPremium />
      <TemporaryPromotion />
      <Promotion />
      <section>
        <LeftPanel />
        <div id="main">
          <section>
            <div id="challenge-header">
              <h2>Challenges (disponible avec l’abonnement Belly Premium)</h2>
            </div>
            <div id="challenge-list">
              {isLoaded ? (
                challenges.length ? (
                  challenges.map((challenge: any, index: number) => (
                    <Card
                      key={`card-${index}`}
                      img={
                        <img
                          src={challenge.preview}
                          alt={`illustration-challenge-${index}`}
                        />
                      }
                      title={challenge.name}
                      action={() =>
                        userChallenges.includes(challenge.id)
                          ? navigate(`/courses-challenge/${challenge.id}`)
                          : (document.location.href =
                              "https://thebellylab.com/belly-premium/#lesformules")
                      }
                      actionTitle={
                        userChallenges.includes(challenge.id)
                          ? "Reprendre"
                          : "Commencer"
                      }
                      disabled={!userChallenges.includes(challenge.id)}
                    />
                  ))
                ) : (
                  <div>
                    <p>
                      Cette partie est destinée aux abonnées. Pour vous abonner,
                      cliquez ci-dessous :
                    </p>
                    <button
                      className="buy-button"
                      onClick={() =>
                        window.open(
                          "https://thebellylab.com/belly-premium/",
                          "_blank"
                        )
                      }
                    >
                      S'abonner
                    </button>
                  </div>
                )
              ) : (
                <p>Chargement des challenges...</p>
              )}
            </div>
          </section>
          {width <= 1000 && <WidgetPanel />}
        </div>
        {width > 1000 && <WidgetPanel />}
      </section>
    </div>
  );
};

export default Challenges;
