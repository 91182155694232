import Button from "../../components/Button/Button";
import HeadbandBellyPremium from "../../components/HeadbandBellyPremium/HeadbandBellyPremium";
import Promotion from "../../components/Promotion/Promotion";
import TemporaryPromotion from "../../components/TemporaryPromotion/TemporaryPromotion";
import useWindowSize from "../../hooks/useWindowSize";
import ApiService from "../../services/ApiService";
import { useGoBack } from "../../services/NavigationService";
import "./CourseChallenge.scss";
import AccordeonItem from "./components/AccordeonItem/AccordeonItem";
import CourseProgressBar from "./components/CourseProgressBar/CourseProgressBar";
import _ from "lodash";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

const CourseChallenge = () => {
  const params = useParams();
  const [isLoaded, setIsLoaded] = useState(false);
  const [course, setCourse] = useState<any>([]);
  const [courseModules, setCourseModules] = useState<
    {
      name: string;
      id: string | null;
      lessons: Array<any>;
    }[]
  >([]);
  const goBack = useGoBack();
  const { width } = useWindowSize();

  useEffect(() => {
    ApiService.get(`e-learning/courses/${params.course_id}`)
      .then((resp) => {
        setCourseModules(resp.data.modules);
        setCourse(resp.data);
        setIsLoaded(true);
      })
      .catch((err) => {
        console.error(err);
        toast.error(
          "Une erreur s'est produite lors de la récupération des données du cours. Veuillez réessayer plus tard."
        );
      });
  }, [params]);

  return (
    <div id="course-challenge">
      <HeadbandBellyPremium />
      <TemporaryPromotion />
      <Promotion />
      <div id="content-course-challenge">
        <div id="course-challenge-button">
          <Button category="primary" onClick={() => goBack()}>
            &lt; Retour
          </Button>
        </div>
        <div
          id="title-container"
          style={{
            backgroundImage: `url(${
              width >= 500
                ? course.illustration
                : course.mobile_illustration ?? course.illustration
            })`,
          }}
        >
          <h2>{course.name?.split(":")?.[0]?.replace(/\d+/g, "")}</h2>
          <h1>{course.name?.split(":")?.[1]}</h1>
        </div>
        {isLoaded && <CourseProgressBar courseId={params.course_id!} />}
        <div className="container">
          {isLoaded &&
            courseModules
              .filter(
                (module) =>
                  !["exercice", "articles", "lives"].includes(module.name)
              )
              .map((module, idx) => (
                <AccordeonItem
                  name={module.name}
                  idx={idx}
                  key={`accordion-title-${idx}`}
                  lessons={_.uniqBy(module.lessons, "id")}
                />
              ))}
        </div>
      </div>
    </div>
  );
};

export default CourseChallenge;
