import Button from "../../components/Button/Button";
import HeadbandBellyPremium from "../../components/HeadbandBellyPremium/HeadbandBellyPremium";
import Modal from "../../components/Modal/Modal";
import Promotion from "../../components/Promotion/Promotion";
import TemporaryPromotion from "../../components/TemporaryPromotion/TemporaryPromotion";
import useWindowSize from "../../hooks/useWindowSize";
import CalendarSVG from "../../icons/Calendar";
import CheckCircleSVG from "../../icons/CheckCircle";
import FileVideoSVG from "../../icons/FileVideo";
import PlayCircleSVG from "../../icons/PlayCircle";
import ReadmeSVG from "../../icons/Readme";
import ApiService from "../../services/ApiService";
import { useGoBack } from "../../services/NavigationService";
import { fetchSurvey, saveSurveyAnswer } from "./BellyPremium.function";
import "./BellyPremium.scss";
import BellyPremiumCard from "./components/BellyPremiumCard/BellyPremiumCard";
import NewsCard from "./components/NewsCard/NewsCard";
import UpcomingCard from "./components/UpcomingCard/UpcomingCard";
import { UpcomingCardProps } from "./components/UpcomingCard/UpcomingCard.d";
import React from "react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const BellyPremium = () => {
  const [bellyPremiumInfo, setBellyPremiumInfo] = useState<any>([]);
  const [highlights, setHighlights] = useState<any>([]);
  const [survey, setSurvey] = useState<any>({});
  const [surveyLoaded, setSurveyLoaded] = useState(false);
  const [dynamicsInfo, setDynamicsInfo] = useState<any>([]);
  const [selectedCheckbox, setSelectedCheckbox] = useState("");
  const { width } = useWindowSize();
  const [viewPlanning, setViewPlanning] = useState(false);
  const navigate = useNavigate();
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    saveSurveyAnswer(survey.id, event.target.name, () => {
      fetchSurvey(setSurvey, setSelectedCheckbox, setSurveyLoaded);
    });
  };
  const goBack = useGoBack();

  const bellyPremiumData = [
    {
      title: "Mes séances",
      action: () => navigate("/courses/50005692-f443-425e-8994-1c2ebf632c19"),
      illustration: "/images/bellypremium/card/previewSeance.jpg",
      icon: <PlayCircleSVG />,
    },
    {
      title: "Mes lives",
      action: () => navigate("/lives/50005692-f443-425e-8994-1c2ebf632c19"),
      illustration: "/images/bellypremium/card/previewLive.jpg",
      icon: <FileVideoSVG />,
    },
    {
      title: "Mes challenges",
      action: () => navigate("/challenges"),
      illustration: "/images/bellypremium/card/previewChallenge.jpg",
      icon: <CheckCircleSVG />,
    },
    {
      title: "Mes articles",
      action: () => navigate("/articles/50005692-f443-425e-8994-1c2ebf632c19"),
      illustration: "/images/bellypremium/card/previewArticle.jpg",
      icon: <ReadmeSVG />,
    },
    {
      title: "Mon planning",
      action: () => setViewPlanning(true),
      illustration: "/images/bellypremium/card/previewPlanning.jpg",
      icon: <CalendarSVG />,
    },
  ];

  useEffect(() => {
    const fetchData = async () => {
      try {
        const coursesAnswer = await ApiService.get(
          "e-learning/courses/50005692-f443-425e-8994-1c2ebf632c19"
        );
        const infos = coursesAnswer.data.lessons.filter((l: any) =>
          l.modules.includes(coursesAnswer.data.modules[0].name)
        );

        setDynamicsInfo(infos);
        const courses =
          coursesAnswer.data?.lessons
            .filter(
              (l: any) =>
                !l.modules.includes(coursesAnswer.data.modules[0].name)
            )
            ?.slice(0, 3) ?? [];
        setBellyPremiumInfo([
          ...courses,
          infos.find((i: any) => i.lesson_type === "article"),
        ]);
        setHighlights(coursesAnswer.data.highlights || []);
      } catch (err) {
        toast.error(
          "Une erreur s'est produite lors de la récupération des données du cours. Veuillez réessayer plus tard."
        );
        console.error(err);
      }
    };

    fetchData();
    fetchSurvey(setSurvey, setSelectedCheckbox, setSurveyLoaded);
  }, []);

  const nextLive = {
    live: dynamicsInfo.find((info: any) => info.lesson_type === "live"),
    buttons: dynamicsInfo.find(
      (info: any) =>
        info.lesson_type === "link" &&
        info.name === "Voir le dernier live en replay"
    ),
  };

  const planning = dynamicsInfo.find(
    (info: any) => info.lesson_type === "link" && info.name === "Planning"
  );

  const upcomingData = [
    {
      title: (
        <h4>
          LE PROCHAIN <b>ARTICLE</b>
        </h4>
      ),
      description: (
        <p className="description">
          {surveyLoaded ? (
            survey ? (
              <span dangerouslySetInnerHTML={{ __html: survey.resume }}></span>
            ) : (
              <span>
                Il n'y a pas de quiz pour le moment. Revenez plus tard
              </span>
            )
          ) : (
            <span>chargement...</span>
          )}
        </p>
      ),
      icon: <ReadmeSVG />,
      children: (
        <div>
          {surveyLoaded ? (
            <React.Fragment>
              {survey?.answers?.map((answer: string, index: number) => (
                <div className="checkbox" key={`answer-${index}`}>
                  <input
                    type="checkbox"
                    id={`answer-${index}`}
                    name={answer}
                    disabled={!!survey.chosen_answer && !!selectedCheckbox}
                    checked={selectedCheckbox === answer}
                    onChange={handleChange}
                  />
                  <label htmlFor={`answer-${index}`}>
                    {answer}
                    <span className="percent-suffix">
                      {survey.chosen_answer || selectedCheckbox
                        ? ` (${survey.answers_percent[answer]}%)`
                        : ""}
                    </span>
                  </label>
                </div>
              ))}
            </React.Fragment>
          ) : (
            <React.Fragment></React.Fragment>
          )}
        </div>
      ),
    },
    {
      title: (
        <h4>
          LE PROCHAIN <b>LIVE</b>
        </h4>
      ),
      subtitle: (
        <p
          className="subtitle"
          dangerouslySetInnerHTML={{ __html: nextLive.live?.source }}
        />
      ),
      description: (
        <p className="description">
          <b>Retrouvez Joëlle chaque premier dimanche du mois à 18h. </b>
          Peu de temps avant cet horaire, un bouton « VOIR LE LIVE » apparaitra
          en haut de la page.
        </p>
      ),
      icon: <FileVideoSVG />,
      children: (
        <div className="children">
          <Button
            category="secondary"
            onClick={() => (document.location.href = nextLive.buttons?.source)}
          >
            VOIR LE DERNIER LIVE EN REPLAY
          </Button>
          <Button
            category="secondary"
            onClick={() =>
              navigate("/lives/50005692-f443-425e-8994-1c2ebf632c19")
            }
          >
            VOIR TOUS LES REPLAYS
          </Button>
        </div>
      ),
    },
  ];

  return (
    <div id="belly-premium">
      <HeadbandBellyPremium />
      <TemporaryPromotion />
      <Promotion />
      <section>
        <div>
          <div id="return-button">
            <Button category="primary" onClick={() => goBack()}>
              &lt; Retour
            </Button>
          </div>
          <div id="title">
            <h2>Bienvenue sur</h2>
            <h2 id="bp">Votre belly premium</h2>
            <p>
              <b>C’est le moment de prendre soin de vous !</b> Retrouvez ici
              tous vos contenus Belly Premium : séances de 15min, challenges,
              lives en replay, articles… pour prendre soin de vous et de votre
              ventre.
            </p>
            <Button category="primary">
              <a href="#news-month">NOUVEAUTÉS DU MOIS</a>
            </Button>
          </div>
          <div id="imgs">
            <img
              src="/images/bellypremium/tacheRose.png"
              alt="tacherose"
              id="tache-rose"
            />
            <img
              src="/images/bellypremium/papillon1.png"
              alt="papillon1"
              id="papillon1-img"
            />
            <img
              src="/images/bellypremium/papillon2.png"
              alt="papillon2"
              id="papillon2-img"
            />
            <img
              src="/images/bellypremium/joelle-bellypremium.png"
              alt="bellypremium"
              id="bellypremium-img"
            />
            <img
              src="/images/bellypremium/papillon3.png"
              alt="papillon3"
              id="papillon3-img"
            />
          </div>
        </div>
      </section>
      <div className="bp-data">
        {bellyPremiumData.map((bpd, index: number) => (
          <BellyPremiumCard key={`belly-premium-data-${index}`} {...bpd} />
        ))}
      </div>
      <div id="news-month">
        <div>
          <p>
            <b>LES NOUVEAUTÉS</b> DU MOIS
          </p>
          <p>Vos nouvelles séances et votre nouvel article sont arrivés !</p>
          <div className="button-list">
            <div>
              <Button
                category="primary"
                onClick={() =>
                  navigate("/courses/50005692-f443-425e-8994-1c2ebf632c19")
                }
              >
                VOIR TOUTES LES SÉANCES
              </Button>
            </div>
            <div>
              <Button
                category="secondary"
                onClick={() =>
                  navigate("/articles/50005692-f443-425e-8994-1c2ebf632c19")
                }
              >
                VOIR TOUS LES ARTICLES
              </Button>
            </div>
          </div>
        </div>
        <div className="lesson-list">
          {bellyPremiumInfo.length > 0 &&
            bellyPremiumInfo.map((lesson: any, index: number) => (
              <NewsCard
                key={`belly-premium-info-${index}`}
                illustration={lesson?.preview ?? "/images/preview_default.png"}
                title={lesson?.name ?? lesson?.title}
                type={lesson?.lesson_type === "article" ? "article" : "seance"}
                link={
                  lesson?.lesson_type !== "article"
                    ? `/lessons/${lesson?.id}`
                    : lesson?.source
                }
              />
            ))}
        </div>
      </div>
      <div className="upcoming-content">
        <div>
          <p>
            LE CONTENU <b>À VENIR</b>
          </p>
          <p>
            Ici découvrez les prochains contenus apparaître dans votre
            abonnement
            <br />
            Belly Premium.
          </p>
        </div>
        <div className="upcoming-cards">
          {upcomingData.map((updata: UpcomingCardProps, index: number) => (
            <UpcomingCard
              key={`upcoming-data-${index}`}
              title={updata.title}
              subtitle={updata?.subtitle}
              description={updata?.description}
              icon={updata?.icon}
            >
              {updata.children}
            </UpcomingCard>
          ))}
        </div>
        {highlights?.map((highlight: any, index: number) => (
          <div className="highlight" key={`highlight-${index}`}>
            <div>
              <p dangerouslySetInnerHTML={{ __html: highlight.text }}></p>
            </div>
            <div>
              <video
                controls
                controlsList="nodownload"
                poster={highlight?.preview}
              >
                <source src={highlight.source} type="video/mp4"></source>
              </video>
            </div>
          </div>
        ))}
      </div>
      {viewPlanning && (
        <Modal
          size={width >= 1200 ? "medium" : "large"}
          content={
            <div className="modal-content">
              <img src={planning?.source} alt={planning?.name} />
            </div>
          }
          onClose={() => setViewPlanning(false)}
        />
      )}
    </div>
  );
};

export default BellyPremium;
